.add-brand-container {
  margin-top: 20px;
  color: #333;
  display: flex;
  gap: 20px;
}
.add-brand {
  background-color: #fff;
  width: 30%;
  padding: 30px;
}
.add-brand input,
.add-model input {
  width: 75%;
  padding: 5px;
  outline: transparent;
  border: 1px solid #333;
  margin-right: 5px;
}
.add-brand button,
.add-model button {
  padding: 5px 20px;
  background-color: #333;
  margin-top: 10px;
  color: #fff;
}
.brands {
  margin-top: 30px;
}
.brand {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin: 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.5);
  &:hover {
    background-color: #333;
    color: #fff;
  }
}
.brand .icon {
  color: red;
  cursor: pointer;
  scale: 1;
  transition: 0.3s all ease;
  &:hover {
    scale: 1.2;
  }
}
.add-model {
  width: 70%;
  background-color: #fff;
  padding: 30px;
}
.add-model input {
  width: 40%;
}
.add-model select {
  padding: 5px;
  margin-right: 5px;
}
