.order-placing-loader {
  width: 100%;
  height: 100vh;
  background-color: var(--t);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.checkout-container {
  padding: 80px 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--s);
  color: #fff;
}
.customer-form {
  width: 50%;
}
.checkout-product-details {
  width: 50%;
  padding: 0 20px;
}
.checkout-item-container {
  background-color: var(--p);
  padding: 20px;
  border-radius: 10px;
}
.checkout-item {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.sv-address {
  padding: 10px;
  border: 2px solid #333;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}
.sv-address.active {
  background-color: rgb(249, 249, 151);
}
.selected {
  position: absolute;
  background-color: #000;
  border-radius: 10px;
  padding: 2px 5px;
  font-size: 10px;
  color: #fff;
  top: -10px;
  right: -10px;
  scale: 0;
}
.selected.ac {
  scale: 1;
}
.order-success-container {
  background-color: var(--s);
  color: #fff;
  height: 80vh;
  display: block;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.order-success-container .icon {
  font-size: 80px;
  background-color: var(--t);
  border-radius: 100px;
  padding: 10px;
  margin-bottom: 20px;
  scale: 1;
  animation: zoom 1s infinite ease-in-out;
}

@keyframes zoom {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.2;
  }
  100% {
    scale: 1;
  }
}

@media screen and (max-width: 992px) {
  .customer-form {
    width: 100%;
  }
  .checkout-product-details {
    width: 100%;
  }
  .checkout-product-details {
    padding: 0;
    margin-top: 20px;
  }
  .order-success-container {
    padding: 30px;
  }
}
@media screen and (max-width: 786px) {
  .checkout-container {
    padding: 80px 20px;
  }
  .order-success-container {
    width: 100%;
  }
}

/* LOADER  */
.order-loader {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--s);
}
.wrapper {
  width: 200px;
  height: 60px;
  position: relative;
  z-index: 1;
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: var(--t);
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 0.5s alternate infinite ease;
}

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.9);
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow046 0.5s alternate infinite ease;
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }

  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
